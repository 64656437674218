import axios from "axios"
import {Loading, Message} from "element-ui";
import { saveAs } from 'file-saver'

let downloadLoadingInstance;
const service = axios.create({
  // baseURL: "/pc-all/prod-api",//正式环境
  baseURL: "/prod-api", //在线测试环境
  // baseURL: "/dev-api",
  // baseURL:'http://124.220.169.216:9010',//本地测试环境
  timeout: 50000,
})
let showLoading = null;
service.interceptors.request.use((config) => {
  config.headers = {
    contentType: "application/json;charset=UTF-8",
  }
  console.log('token',localStorage.getItem("token"))
  config.headers["Token"] =
    localStorage.getItem("token") || "undefined"
    // "eyJhbGciOiJIUzI1NiJ9.eyJMT0dJTl9VU0VSX0tFWSI6IjliYjlhN2JiLTU4NGYtNDlmNC1iMDEzLTM2YzE2MzNkZTBmNCJ9.uXw2T1JrAwpUsRP3HTTIkUGaW1VDdhs1OQqgGfO6BCE"
  showLoading = Loading.service({
    lock: true,
    text: "加载中...",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0)",
  });
  return config
})

service.interceptors.response.use(
  (response) => {
    showLoading.close()
    return response.data
  },
  (error) => {
    showLoading.close()
    console.log(error);
    // return Promise.reject(error)
  },
)

export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

// 验证是否为blob格式
function blobValidate(data) {
  return data.type !== 'application/json'
}

/**
 * 参数处理
 * @param {*} params  参数
 */
function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}

export default service
