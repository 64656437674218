import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)
const routes = [
  {
    path: "/",
    redirect: "/sage",
  },
  {
    path: "/statistics",
    component: () => import("@/views/Project/Sage/statistics.vue"),
    meta: {},
  },
  {
    path: "/addRecords",
    component: () => import("@/views/Project/Sage/addRecords.vue"),
    meta: {},
  },
  {
    path: "/modifyRecords",
    component: () => import("@/views/Project/Sage/modifyRecords.vue"),
    meta: {},
  },
  {
    path: "/home",
    component: () => import("@/views/Project/Home/project.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/homeDetail",
    component: () => import("@/views/Project/Home/detail.vue"),
    meta: {
    },
  },
  {
    path: "/category",
    component: () => import("@/views/Project/Category/category.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/categoryDetail",
    component: () => import("@/views/Project/Category/detail.vue"),
    meta: {
    },
  },
  {
    path: "/editing",
    component: () => import("@/views/Project/Editing/editing.vue"),
    meta: {
    },
  },
  {
    path: "/sharedPool",
    component: () => import("@/views/Project/SharedPool/sharedPool.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/External",
    component: () => import("@/views/Project/External/external.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/externalDetail",
    component: () => import("@/views/Project/External/detail.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/sharedClaim",
    component: () => import("@/views/Project/SharedClaim/sharedClaim.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/sharedClaimDetail",
    component: () => import("@/views/Project/SharedClaim/detail.vue"),
    meta: {
    },
  },
  {
    path: "/reviewed",
    component: () => import("@/views/Project/Reviewed/reviewed.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/major",
    component: () => import("@/views/Project/Major/major.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/punchCard",
    component: () => import("@/views/Project/PunchCard/punchCard.vue"),
    meta: {},
  },
  {
    path: "/punchCardDetail",
    component: () => import("@/views/Project/PunchCard/detail.vue"),
    meta: {},
  },
  {
    path: "/compile",
    component: () => import("@/views/Project/PunchCard/compile.vue"),
    meta: {},
  },
  {
    path: "/sage",
    component: () => import("@/views/Project/Sage/sage.vue"),
    meta: {keepAlive: true},
  },
  {
    path: "/sagePos",
    component: () => import("@/views/Project/Sage/sagePos.vue"),
    meta: {},
  },
  {
    path: "/sagePosDynamic",
    component: () => import("@/views/Project/Sage/sagePosDynamic.vue"),
    meta: {},
  },
  {
    path: "/sageCollect",
    component: () => import("@/views/Project/Sage/sageCollect.vue"),
    meta: {},
  },
  {
    path: "/gather",
    component: () => import("@/views/Project/Sage/gather.vue"),
    meta: {},
  },
  {
    path: "/application",
    component: () => import("@/views/Project/Sage/application.vue"),
    meta: {},
  },
  {
    path: "/approval",
    component: () => import("@/views/Project/Sage/approval.vue"),
    meta: {},
  },
  {
    path: "/emphasis",
    component: () => import("@/views/Project/Sage/emphasis.vue"),
    meta: {},
  },
  {
    path: "/records",
    component: () => import("@/views/Project/Sage/records.vue"),
    meta: {},
  },
  {
    path: "/sharedList",
    component: () => import("@/views/Project/SharedList/sharedList.vue"),
    meta: {keepAlive: true},
  }, {
    path: "/sharedDetail",
    component: () => import("@/views/Project/SharedList/detail.vue"),
    meta: {},
  },
  {
    path: "/massTexting",
    component: () => import("@/views/Project/Sage/massTexting.vue"),
    meta: {},
  },
  {
    path: "/patent",
    component: () => import("@/views/Project/innovativeTechnology/patent.vue"),
    meta: {},
  },
  {
    path: "/thinkTank",
    component: () => import("@/views/Project/innovativeTechnology/thinkTank.vue"),
    meta: {},
  },
  {
	path: "/technicalStandard",
	component: () => import("@/views/Project/innovativeTechnology/technicalStandard.vue"),
	meta: {
		keepAlive:true
	},
  },
  {
  	path: "/technicalStandardDetail",
  	component: () => import("@/views/Project/innovativeTechnology/technicalStandardDetail.vue"),
  	meta: {},
  }
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode:'history', //本地测试环境、在线测试环境
  // mode: "hash", //正式环境
  // base:"/pc-all/", //正式环境
  // base: process.env.BASE_URL,
  routes,
})

export default router
