import request from "@/axios/index"
// 项目共享池
export const homeList = (query) => {
  return request({
    // url: "/merchants/projectInvest/getShareExecutorList",
    url: "/merchants/project/executorProjectList",
    method: "get",
    params: query,
  })
}

//项目阶段
export const systemCode = (m_project_status) => {
  return request({
    url: `/system/dict/data/type/${m_project_status}`,
    method: "get",
  })
}
//项目级别
export const shareCode = (m_project_share_status) => {
  return request({
    url: `/system/dict/data/type/${m_project_share_status}`,
    method: "get",
  })
}

//科技阶段行业

export const technologyCode = (m_project_technology_team_type) => {
  return request({
    url: `/system/dict/data/type/${m_project_technology_team_type}`,
    method: "get",
  })
}
// 项目类别
export const projectCode = (m_share_project_type) => {
  return request({
    url: `/system/dict/data/type/${m_share_project_type}`,
    method: "get",
  })
}

// 项目合作方式
export const cooperationCode = (m_project_cooperation_method) => {
  return request({
    url: `/system/dict/data/type/${m_project_cooperation_method}`,
    method: "get",
  })
}

// 跟进方式
export const dockingTitle = (docking_title) => {
  return request({
    url: `/system/dict/data/type/${docking_title}`,
    method: "get",
  })
}

// 项目共享排名
export const getCityCount = (query) => {
  return request({
    // url: `/merchants/projectInvest/getCityCount`,
    url: `/merchants/project/getCityCount`,
    method: "get",
    params: query,
  })
}

// 领取
export const takeList = (query) => {
  return request({
    // url: `/merchants/projectInvest/getShareExecutorTakeList`,
    url: `/merchants/project/getShareLog`,
    method: "get",
    params: query,
  })
}

// 投资详情
// export const detail = (code) => {
//   return request({
//     url: `/merchants/projectInvest/${code}`,
//     method: "get",
//   })
// }

// // 科技详情
// export const details = (code) => {
//   return request({
//     url: `/merchants/projectTechnology/${code}`,
//     method: "get",
//   })
// }


export const details = (projectId, projectType) => {
  return request({
    url: `/merchants/project/${projectId}/${projectType}`,
    method: "get",
  })
}






// 项目领取 接收或者拒绝
export const shareEdit = (shareId) => {
  return request({
    // url: `/merchants/projectShare/shareEdit/${query}/${code}`,
    url: `/merchants/share/takeProject/${shareId}`,
    method: "post",
  })
}

// 外部项目领取接收
export const cityAdminTakeProject = (data) => {
  return request({
    // url: `/merchants/projectShare/shareEdit/${query}/${code}`,
    url: `/merchants/share/cityAdminTakeProject`,
    method: "post",
    params: data
  })
}

//科技投资项目列表   -------
export const getMProjectlnvestList = (query) => {
  return request({
    // url: "/merchants/projectInvest/getMProjectInvestList",
    url: "/merchants/project/projectList",
    method: "get",
    params: query,
  })
}

//投资 所属行业
export const industry = (m_project_invest_industry) => {
  // return request({
  //   url: '/merchants/projectCategory/tree',
  //   method: 'get',
  //   data: data,
  // })
  return request({
    url: `/system/dict/data/type/${m_project_invest_industry}`,
    method: "get",
  })
}

// 科技类行业 415
export const technology_industry = (four_fifteen) => {
  // return request({
  //   url: `/system/dict/data/type/${m_project_technology_industry}`,
  //   method: "get",
  // })
  return request({
    url: `/system/dict/data/type/${four_fifteen}`,
    method: "get",
  })
}

// 项目分配
export const allotProject = (data) => {
  return request({
    url: `/merchants/share/allotProject`,
    method: "post",
    params: data

  })
}

// 项目阶段科技
export const construction = (m_project_construction_period) => {
  return request({
    url: `/system/dict/data/type/${m_project_construction_period}`,
    method: "get",
  })
}

// 投资新增
// export const projectInvest = (data) => {
//   return request({
//     url: "/merchants/projectInvest",
//     method: "post",
//     data: data,
//   })
// }

// /merchants/ projectTechnology

// 科技新增
export const projectTechnologyPos = (data) => {
  return request({
    url: "/merchants/projectTechnology",
    method: "post",
    data: data,
  })
}

// 机构类型
export const mechanismCode = (m_project_invest_agency_type) => {
  return request({
    url: `/system/dict/data/type/${m_project_invest_agency_type}`,
    method: "get",
  })
}

//团队类型
export const teamCode = (m_project_technology_team_type) => {
  return request({
    url: `/system/dict/data/type/${m_project_technology_team_type}`,
    method: "get",
  })
}

//科技成果类列表
export const getMProjectTechnologyList = (query) => {
  return request({
    url: `/merchants/projectTechnology/getMProjectTechnologyList`,
    method: "get",
    params: query,
  })
}

//对外共享项目
export const getShareToCity = (query) => {
  return request({
    // url: `/merchants/projectInvest/getShareToCity`,
    url: `/merchants/project/getUserShareOtherUser`,
    method: "get",
    params: query,
  })
}

//外部共享项目
export const getToUserList = (query) => {
  return request({
    // url: `/merchants/projectShare/getToUserList`,
    url: `/merchants/project/getOtherUserShareUser`,
    method: "get",
    params: query,
  })
}

// 共享池项目领取
export const getTaksList = (query) => {
  return request({
    // url: `/merchants/projectInvest/getTakeList`,
    url: `/merchants/project/getExecutorTakePorject`,
    method: "get",
    params: query,
  })
}

// 重点项目
export const getImportantProjectList = (query) => {
  return request({
    // url: `/merchants/projectInvest/getImportantProjectList`,
    url: `/merchants/projectCollect/list`,
    method: "get",
    params: query,
  })
}

// 项目待审批列表
export const getAuditingProjectList = (query) => {
  return request({
    // url: `/merchants/projectInvest/getAuditingProjectList`,
    url: `/merchants/project/getAuditingPorjectList`,
    method: "get",
    params: query,
  })
}

// 共享项目退回
export const shareEdits = (shareId) => {
  return request({
    // url: `/merchants/projectShare/shareExecutorEdit/${code}`,
    url: `/merchants/share/shareExecutorEdit/${shareId}`,
    method: "post",
  })
}

// 项目阶段管理  ---修改
export const projectPost = (data) => {
  return request({
    // url: `/merchants/projectInvest`,
    url: `/merchants/project`,
    method: "put",
    data: data,
  })
}

// 科技项目阶段管理修改
export const projectTechnology = (data) => {
  return request({
    url: `/merchants/projectTechnology`,
    method: "put",
    data: data,
  })
}

//市县
export const treeData = () => {
  return request({
    url: `/merchants/areas/tree`,
    method: "get",
  })
}

//京津冀省市县
export const getPlaceData = () => {
  return request({
    url: `/merchants/jAreas/tree`,
    method: "get",
  })
}

// // 投资项目删除
// export const projectDelte = (projectId) => {
//   return request({
//     url: `/merchants/projectInvest/${projectId}`,
//     method: "delete",
//   })
// }
// //科技项目删除
// export const projectTechnologyDelete = (projectNos) => {
//   return request({
//     url: `/merchants/projectTechnology/${projectNos}`,
//     method: "delete",
//   })
// }

// 共享方式
export const getShareTypes = () => {
  return request({
    // url: `/merchants/projectInvest/getShareType`,
    url: `/merchants/share/getShareType`,
    method: "get",
  })
}

// 对接日志
export const listLog = (query) => {
  return request({
    url: `/merchants/projectDockingLog/list`,
    method: "get",
    params: query,
  })
}
// 图片上传
export const projectDockingLog = (data) => {
  return request({
    url: `/merchants/projectDockingLog/upload`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  })
}

// 发布信息
export const projectDockingLogPos = (data) => {
  return request({
    url: `/merchants/projectDockingLog`,
    method: "post",
    data: data,
  })
}

// 删除
export const projectDrlete = (id) => {
  return request({
    url: `/merchants/projectDockingLog/${id}`,
    method: "delete",
  })
}

// 修改日志信息
export const projectDockingLogPut = (data) => {
  return request({
    url: `/merchants/projectDockingLog`,
    method: "put",
    data,
  })
}

// 市内共享---投资
export const shareToCityPos = (data) => {
  return request({
    url: `/merchants/projectInvest/shareToCity`,
    method: "post",
    data,
  })
}
// 科技类
export const shareToCityPosKj = (data) => {
  return request({
    url: `/merchants/projectTechnology/shareToCity`,
    method: "post",
    data,
  })
}

// 科技修改
export const projectInvestPut = (data) => {
  return request({
    url: `/merchants/projectInvest`,
    method: 'put',
    data: data,
  })
}

// 市际共享---投资
export const shareToIntercityPos = (data) => {
  return request({
    url: `/merchants/projectInvest/shareToIntercity`,
    method: "post",
    data,
  })
}
// 市际共享---科技
export const shareToIntercityPosKj = (data) => {
  return request({
    url: `/merchants/projectTechnology/shareToIntercity`,
    method: "post",
    data,
  })
}

// 共享给全省----投资
// export const shareExecutorPos = (data) => {
//   return request({
//     url: `/merchants/projectInvest/shareExecutor`,
//     method: "post",
//     data,
//   })
// }
// 投资科技全省
export const shareExecutorPos = (data) => {
  return request({
    url: `/merchants/projectInvest/shareExecutor`,
    method: "post",
    data,
  })
}

// 共享给全省----科技
// export const shareExecutorkj = (data) => {
//   return request({
//     url: `/merchants/projectTechnology/shareExecutor`,
//     method: "post",
//     data,
//   })
// }

// 待审批通过未通过
export const auditingProject = (query) => {
  return request({
    url: `/merchants/share/auditingProject`,
    method: "post",
    params: query
  })
}

//走访打卡
export const interviewclockList = (query) => {
  return request({
    url: `/merchants/interviewclock/list`,
    method: "get",
    params: query,
  })
}

//走访目标
export const interviewCode = (interview_target) => {
  return request({
    url: `/system/dict/data/type/${interview_target}`,
    method: "get",
  })
}
//走访类型
export const interviewCodes = (interview_type) => {
  return request({
    url: `/system/dict/data/type/${interview_type}`,
    method: "get",
  })
}

// 走访详情
export const intervieCk = (id) => {
  return request({
    url: `/merchants/interviewclock/${id}`,
    method: "get",
  })
}

// 走访打卡新增图片上传
export const interviewPos = (data) => {
  return request({
    url: `/merchants/common/upload`,
    method: "post",
    data: data,
  })
}

// 修改打卡
export const interviePut = (data) => {
  return request({
    url: `/merchants/interviewclock`,
    method: "put",
    data: data,
  })
}

// 新增打卡
export const interviePost = (data) => {
  return request({
    url: `/merchants/interviewclock`,
    method: "post",
    data: data,
  })
}

// 删除打卡

export const intervieDelete = (id) => {
  return request({
    url: `/merchants/interviewclock/${id}`,
    method: "delete",
  })
}

// 乡贤----------------

// 自有列表
export const eliteszjbList = (query) => {
  return request({
    url: `/merchants/eliteszjb/partList`,
    method: "get",
    params: query,
  })
}

// 全部列表
export const eliteAllList = (query) => {
  return request({
    url: `/merchants/eliteszjb/allList`,
    method: "get",
    params: query,
  })
}

// 乡贤类别
export const typeList = (elite_type) => {
  return request({
    url: `system/dict/data/type/${elite_type}`,
    method: "get",
  })
}

// 新增 无需审批
export const eliteszjbPos = (data) => {
  return request({
    url: `/merchants/eliteszjb`,
    method: "post",
    data: data
  })
}

// 新增2 需要审核
export const eliteszjbPosV2 = (data) => {
  return request({
    url: `/merchants/addApply/add`,
    method: "post",
    data: data
  })
}

/* 修改 无需审批 */
export const eliteszjbPut = (data) => {
  return request({
    url: `/merchants/eliteszjb`,
    method: "put",
    data: data
  })
}

// 修改 需要审核
export const eliteszjbPutV2 = (data) => {
  return request({
    url: `/merchants/modifyApply/add`,
    method: "post",
    data: data
  })
}

// 详情
export const eliteszjbId = (id) => {
  return request({
    url: `/merchants/eliteszjb/${id}`,
    method: "get",
  })
}

// 删除详情
export const eliteszDelete = (id) => {
  return request({
    url: `/merchants/eliteszjb/${id}`,
    method: "delete",
  })
}

/* 协同新增关注 */
export const projectCollect = (data) => {
  return request({
    url: `/merchants/projectCollect`,
    method: "post",
    data: data
  })
}
/* 协同取消关注 */
export const cillectDel = (data) => {
  return request({
    url: `/merchants/projectCollect/${data.projectId}`,
    method: "delete",
  })
}

/* 协同新增关注 */
export const cillectPosd = (data) => {
  return request({
    url: `/merchants/collect`,
    // url: `/merchants/projectCollect`,
    method: "post",
    data: data
  })
}
/* 乡贤取消关注 */
export const cillectDel1 = (collectId) => {
  return request({
    url: `/merchants/collect/${collectId}`,
    method: "delete",
  })
}
//查询乡贤审批列表
export const examineList = (query) => {
  return request({
    url: `/merchants/examineApply/examineList`,
    method: "get",
    params: query
  })
}

// 查询乡贤申请列表
export const applyList = (query) => {
  return request({
    url: `/merchants/examineApply/applyList`,
    method: "get",
    params: query
  })
}

// 查询记录
export const getRecord = (query) => {
  return request({
    url: `/merchants/examineApply/getRecord`,
    method: "get",
    params: query
  })
}

// 乡贤审批状态
export const examineApplyPus = (data) => {
  return request({
    url: `/merchants/examineApply`,
    method: "put",
    data
  })
}

// 乡贤申请状态
export const examineApplyPos = (data) => {
  return request({
    url: `/merchants/examineApply`,
    method: "post",
    data
  })
}

// 重点关注列表
export const collectList = (query) => {
  return request({
    url: `/merchants/collect/list`,
    // url: `/merchants/projectCollect/list`,
    method: "get",
    params: query
  })
}

// 权限
export const getRole = () => {
  return request({
    url: `/merchants/elitesRole/getRole`,
    method: "get",
  })
}


// 发送短信
export const infoCode = (data) => {
  return request({
    url: `/merchants/eliteszjb/sms/code`,
    method: "post",
    data
  })
}



// 审批详情
export const examineApplyCode = (code) => {
  return request({
    url: `/merchants/examineApply/${code}`,
    method: "get",
  })
}


// 投资415
export const Industrial = (m_project_Industrial_cluster) => {
  return request({
    url: `/system/dict/data/type/${m_project_Industrial_cluster}`,
    method: "get",
  })
}

// 生日提醒
export const getElitesBirthday = (query) => {
  return request({
    url: `/merchants/collect/getElitesBirthday`,
    method: "get",
    params: query
  })
}

// 根据状态是否提醒生日
export const isElitesBirthdayOnclick = (query) => {
  return request({
    url: `/merchants/collect/isElitesBirthdayOnclick`,
    method: "get",
    params: query
  })
}


// 协同角色权限
export const projectUserRole = () => {
  return request({
    url: `/merchants/projectUserRole/getUserRole`,
    method: "get",
  })
}


// 查询共享方式列表
export const getShareTypeList = (query) => {
  return request({
    url: `/merchants/project/getShareLog`,
    method: "get",
    params: query
  })
}

// 获取用户
export const getUserInfos = () => {
  return request({
    url: `/merchants/projectUserRole/getUser`,
    method: "get",
  })
}



// 短信导入多个
export const sendSmsEliteList = ()=>{
  return request({
    url: `/merchants/eliteszjb/sendSmsEliteList`,
    method: "get",
  })
}


// 乡贤籍贯地区新接口
export const getEliteNativeCode = ()=>{
  return request({
    url: `/merchants/areas/getEliteNativeCode`,
    method: "get",
  })
}

// 乡贤籍贯地区新接口
export const getInterviewArea = ()=>{
  return request({
    url: `/merchants/areas/getInterviewArea`,
    method: "get",
  })
}

// 乡贤籍贯地区新接口
export const getEliteNativeCodeMultiple = ()=>{
  return request({
    url: `/merchants/areas/getEliteNativeCodeMultiple`,
    method: "get",
  })
}


// 走访打卡添加乡贤
export const countrySquire = (query)=>{
  return request({
    url: `/merchants/interviewclock/eliteList`,
    method: "get",
    params:query
  })
}

// 乡贤数据采集
export const addCollectData = (data = {}) => {
  return request({
    url: `/merchants/gather`,
    method: "post",
    data,
  })
}

//乡贤数据采集审核列表
export const getGatherList = (data = {}) => {
  return request({
    url: `/merchants/gather/list`,
    method: "get",
    params:data,
  })
}

//乡贤数据采集详情
export const getGatherInfo = (data = {}) => {
  return request({
    url: `/merchants/gather/${data.id}`,
    method: "get"
  })
}

//删除乡贤数据采集审核
export const deleteGatherInfo = (data = {}) => {
	return request({
		url: `/merchants/gather/${data.id}`,
		method: "delete"
	})
}

//编辑乡贤数据采集
export const setGatherInfo = (data = {}) => {
	return request({
		url: `/merchants/gather/examine`,
		method: "put",
		data
	})
}

// 我的新增列表
export const addMyListApi = (query) => {
  return request({
    url: `/merchants/addApply/myList`,
    method: "get",
    params: query
  })
}

// 新增审核列表
export const applyListApi = (query) => {
  return request({
    url: `/merchants/addApply/applyList`,
    method: "get",
    params: query
  })
}

// 新增审核 0待审核，1通过，2不通过
export const addCheckApi = (data) => {
  return request({
    url: `/merchants/addApply`,
    method: "put",
    data: data
  })
}

// 详情
export const addDetailApi = (id) => {
  return request({
    url: `/merchants/addApply/${id}`,
    method: "get",
  })
}

// 新增 编辑后再次申请
export const addUpdateApi = (data) => {
  return request({
    url: `/merchants/addApply/update`,
    method: "post",
    data
  })
}
// 导出我的新增Post：/merchants/addApply/exportMyList
// 导出审批列表Post：/merchants/addApply/exportApplyList

// 我的修改列表
export const modifyMyListApi = (query) => {
  return request({
    url: `/merchants/modifyApply/myList`,
    method: "get",
    params: query
  })
}

// 修改审核列表
export const modifyApplyListApi = (query) => {
  return request({
    url: `/merchants/modifyApply/applyList`,
    method: "get",
    params: query
  })
}

// 修改审核 0待审核，1通过，2不通过
export const modifyCheckApi = (data) => {
  return request({
    url: `/merchants/modifyApply`,
    method: "put",
    data: data
  })
}

// 修改详情
export const modifyDetailApi = (id) => {
  return request({
    url: `/merchants/modifyApply/${id}`,
    method: "get",
  })
}

// 新增再次申请
export const addReSubmitApi = (id) => {
  return request({
    url: `/merchants/addApply/reSubmit/${id}`,
    method: "get",
  })
}

// 新增再次申请
export const modifyReSubmitApi = (id) => {
  return request({
    url: `/merchants/modifyApply/reSubmit/${id}`,
    method: "get",
  })
}

// 修改后申请
export const modifyUpdateApi = (data) => {
  return request({
    url: `/merchants/modifyApply/update`,
    method: "post",
    data
  })
}

// 统计
export const statisticApi = (data) => {
  return request({
    url: `/merchants/statistic/list`,
    method: "post",
    data
  })
}

// 区县统计
export const countrylistApi = (data) => {
  return request({
    url: `/merchants/statistic/countrylist`,
    method: "post",
    data
  })
}

//项目所属地区
export const getProjecArea = () => {
  return request({
    url: `/merchants/areas/getProjecArea`,
    method: "get"
  })
}

//项目导出
export const projecExport = (query) => {
  return request({
    url: `/merchants/project/export`,
    method: "get",
    responseType: "blob",
    params: query
  })
}

//走访打卡项目导出
export const cardProjecExport = (data) => {
  return request({
    url: `/merchants/interviewclock/export`,
    method: "post",
    responseType: "blob",
    data
  })
}

// 走访打卡角色权限
export const cardProjectUserRole = () => {
  return request({
    url: `/merchants/interviewclock/getUserRole`,
    method: "get",
  })
}

// 协同共享项目撤回
export const getProjectShareCountermand = (query) => {
  return request({
    url: `/merchants/share/getProjectShareCountermand`,
    method: "get",
    params: query
  })
}

// 投资项目投资金额为空的列表
export const getTotalInvestmentIsNullProject = () => {
  return request({
    url: `/merchants/project/getTotalInvestmentIsNullProject`,
    method: "get"
  })
}
// 投资、科技项目阶段管理修改
export const editProjectStatus = (data) => {
  return request({
    url: `/merchants/project/editProjectStatus`,
    method: "put",
    data: data,
  })
}

// 专利列表
export const getPatentList = (data = {}) => {
  return request({
    url: `/merchants/patent/list`,
    method: "get",
	params:data
  })
}

// 专利详情
export const patentDetail = (data = {}) => {
  return request({
    url: `/merchants/patent/${data.id}`,
    method: "get"
  })
}

// 技术标准列表
export const getStandardList = (data = {}) => {
  return request({
    url: `/merchants/standard/list`,
    method: "get",
	params:data
  })
}

// 技术标准详情
export const standardDetail = (data = {}) => {
  return request({
    url: `/merchants/standard/${data.id}`,
    method: "get"
  })
}
// 智库列表
export const getTankList = (data = {}) => {
  return request({
    url: `/merchants/tank/list`,
    method: "get",
	params:data
  })
}

// 智库详情
export const tankDetail = (data = {}) => {
  return request({
    url: `/merchants/tank/${data.id}`,
    method: "get"
  })
}

// 获取产业集群
export const getPublicDictType = (dictType) => {
  return request({
    url: `/system/dict/data/public/type/${dictType}`,
    method: "get"
  })
}